import { ShippingApi } from "../api";
import { showErrorNotification } from "../utils/error";

const getShippingList = ({ setLoading, params, callback }) => {
  if(setLoading) setLoading(true)
  ShippingApi.getShippingList(params)
    .then(response => {
      let { status } = response
      if (status === 200) {
        if(callback) callback(response.data)
      }
    })
    .catch((err) => {
      showErrorNotification(err)
    }).finally(() => setLoading && setLoading(false))
}

const getTrackingStatus = ({ setLoading, callback }) => {
  if(setLoading) setLoading(true)
  ShippingApi.getTrackingStatus()
    .then(response => {
      let { status } = response
      if (status === 200) {
        if(callback) callback(response.data)
      }
    })
    .catch((err) => {
      showErrorNotification(err)
    }).finally(() => setLoading && setLoading(false))
}

const getShippingCount = ({ cards, callback }) => {
  ShippingApi.getShippingCount()
    .then(response => {
      let { status, data } = response
      if (status === 200) {
        if(callback) callback(cards.map(v => {
          v.value = data[v.key]
          return v
        }))
      }
    })
    .catch((err) => {
      showErrorNotification(err)
    });
}

const getShippingDetail = ({ id, setLoading, callback }) => {
  if(setLoading) setLoading(true)
  ShippingApi.getShippingDetail(id)
  .then(response => {
    const { data, status } = response
    if(status === 200) {
      if(callback) callback(data)
    }
  }).catch(err => {
    showErrorNotification(err)
  }).finally(() => setLoading && setLoading(false))
}

export const Shipping = () => {
  return {
    list: (params) => getShippingList(params),
    count: (params) => getShippingCount(params),
    detail: (params) => getShippingDetail(params),
    trackingStatus: (params) => getTrackingStatus(params)
  }
}

export default Shipping