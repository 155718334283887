import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

export const ButtonPrimary = ({
  className,
  size,
  htmlType,
  loading,
  children,
}) => {
  return (
    <Button
      className={`${size} ${className}`}
      type="primary"
      htmlType={htmlType}
      loading={loading}
    >
      {children}
    </Button>
  );
};

ButtonPrimary.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  htmlType: PropTypes.string,
  loading: PropTypes.bool,
};

ButtonPrimary.defaultProps = {
  className: '',
  size: 'btn-medium',
  htmlType: 'button',
  loading: false,
  children: '',
};

// ------------------------------------------------------------------------------------------------------ //

export const ButtonLink = ({ className, size, children, loading }) => {
  return (
    <Button
      className={`btn-font-normal ${size} ${className}`}
      type="link"
      loading={loading}
    >
      {children}
    </Button>
  );
};

ButtonLink.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
};

ButtonLink.defaultProps = {
  className: '',
  size: 'btn-medium',
  loading: false,
  children: '',
};

// ------------------------------------------------------------------------------------------------------ //
