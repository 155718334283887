import React from 'react';
import { Layout } from 'antd'

const { Footer } = Layout;
const d = new Date()

const MainFooter = () => {


  return (
    <Footer className="text-center">
      &copy; { d.getFullYear() } Rimkirim All Rights Reserved
    </Footer>
  );
};

export default MainFooter;