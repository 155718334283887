import React from 'react';
import { Table } from 'antd';
import TableControl from '../table-control';

const MainTable = (props) => {

  const { expandedRowRender, expandIcon } = props

  return (
    <div className="main-table">
      <TableControl {...props}>
        <Table
          {...props}
          pagination={false}
          rowKey={record => record._id}
          expandable={{ expandedRowRender }}
          expandIcon={expandIcon}
          style={{ overflowX: 'scroll' }}
        />
      </TableControl>
    </div>
  );
};

export default MainTable;