import { Form, Col, Row, Select, Input } from "antd"
import { isPackageTypeDocument } from "../../../utils/misc"
import { getArrayOfNumber } from '../../../utils/array'

export const ShipmentItem = ({
  field,
  loading,
  packageType,
  weightUnit,
  onChange,
  disabled,
  cols
}) => {
  return (
    <Row gutter={[15, 15]} className="w-full">
      <Col {...cols.colsPackage2}>
        <Form.Item
          name={[field.name, "quantity"]}
          label="Jumlah"
          rules={[
            { required: true, message: 'Isi Jumlah' },
          ]}
          style={{ marginBottom: 0 }}
        >
          {/* <Input type="number" min={1} max={99}/> */}
          <Select
            showSearch
            showArrow={false}
            defaultActiveFirstOption={1}
            disabled={true} // loading || disabled
            autoComplete="off"
            onChange={value => onChange(value, [field.name, "quantity"])}
            placeholder="Jumlah"
            style={{ minWidth: 65 }}
            className="w-full"
          >
            {getArrayOfNumber(99).map(v => <Select.Option value={v} key={v}>{v}</Select.Option>)}
          </Select>
        </Form.Item>
      </Col>
      {
        !isPackageTypeDocument(packageType) &&
        <>
          <Col {...cols.colsPackage}>
            <Form.Item
              name={[field.name, "length"]}
              label="Panjang"
              rules={[
                { required: true, message: 'Isi Panjang' },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input
                className="w-full"
                min={1}
                max={1000}
                disabled={loading || disabled}
                addonAfter="cm"
                autoComplete="off"
                onChange={e => onChange(e.target.value, [field.name, "length"])}
                placeholder="Panjang"
              />
            </Form.Item>
          </Col>
          <Col {...cols.colsPackage}>
            <Form.Item
              name={[field.name, "width"]}
              label="Lebar"
              rules={[
                { required: true, message: 'Isi Lebar' },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input
                className="w-full"
                min={1}
                max={1000}
                disabled={loading || disabled}
                addonAfter="cm"
                autoComplete="off"
                onChange={e => onChange(e.target.value, [field.name, "width"])}
                placeholder="Lebar"
              />
            </Form.Item>
          </Col>
          <Col {...cols.colsPackage}>
            <Form.Item
              name={[field.name, "height"]}
              label="Tinggi"
              rules={[
                { required: true, message: 'Isi Tinggi' },
              ]}
              style={{ marginBottom: 0 }}
            >
              <Input
                className="w-full"
                min={1}
                max={1000}
                disabled={loading || disabled}
                addonAfter="cm"
                autoComplete="off"
                onChange={e => onChange(e.target.value, [field.name, "height"])}
                placeholder="Tinggi"
              />
            </Form.Item>
          </Col>
        </>
      }
      <Col {...cols.colsPackage3}>
        <Form.Item
          name={[field.name, "weight"]}
          label="Berat"
          rules={[
            { required: true, message: 'Isi Berat' },
          ]}
          style={{ marginBottom: 0 }}
        >
          <Input
            className="w-full"
            min={.1}
            max={100}
            disabled={loading || disabled}
            addonAfter={weightUnit}
            autoComplete="off"
            onChange={e => onChange(e.target.value, [field.name, "weight"])}
            placeholder="Berat"
          />
        </Form.Item>
      </Col>
    </Row>
  )
}