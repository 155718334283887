import React from 'react'
import { Layout } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons';

const { Header } = Layout;

const MainHeader = ({ pageTitle = '', siderCollapsed = false, setSiderCollapsed }) => {
  return (
    <Header>
      <div>
        {React.createElement(siderCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          onClick: () => setSiderCollapsed(prev => !prev),
        })}
      </div>
      <b>{pageTitle}</b>
      <div>...</div>
    </Header>
  );
};

export default MainHeader;