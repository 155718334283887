import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const { Item } = Form;

export const FormItemEmail = ({ className, disabled, initialValue }) => {
  return (
    <Item
      label="Email"
      name="email"
      rules={[
        { required: true },
        { type: 'email' }
      ]}
      className={className}
      initialValue={initialValue}
    >
      <Input disabled={disabled} />
    </Item>
  );
};

FormItemEmail.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

FormItemEmail.defaultProps = {
  className: '',
  disabled: false,
};

// ------------------------------------------------------------------------------------------------------ //

export const FormItemPassword = ({ className, disabled, initialValue }) => {
  return (
    <Item
      label="Password"
      name="password"
      rules={[{ required: true }]}
      className={className}
      initialValue={initialValue}
    >
      <Input.Password disabled={disabled} />
    </Item>
  );
};

FormItemPassword.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

FormItemPassword.defaultProps = {
  className: '',
  disabled: false,
};
