import axios from 'axios';

const Shipping = ({ url, config, defaultParams }) => {
  let endpoint = '/v1/admin'
  return {
    getShippingList: params => axios.get(`${url}${endpoint}/shipping`, { params: { ...defaultParams, ...params }, ...config() }), 
    getShippingDetail: id => axios.get(`${url}${endpoint}/shipping/${id}`, config()), 
    getShippingCount: () => axios.get(`${url}${endpoint}/shipping/count`, config()), 
    getTrackingStatus: () => axios.get(`${url}${endpoint}/dropdown/tracking-status`, config()), 
    searchShipping: params => axios.get(`${url}${endpoint}/shipping/${params}`, config()),
    updateStatus: payload => axios.patch(`${url}${endpoint}/shipping/${payload.id}`, payload, config()),
  };
};

export default Shipping;