import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import AppRouter from './router';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.less';
import './styles/scss/index.scss';
import idID from 'antd/lib/locale/id_ID'

ReactDOM.render(
  <ConfigProvider locale={idID}>
    <AppRouter />
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
