import { PACKAGE_TYPE, PACKAGE_TYPE_DOCUMENT } from "../config"

export const getServiceTypePrice = (serviceType, serviceTypeList) => {
  return serviceTypeList.filter(v => v.name === serviceType)[0]?.price || 0
}

export const isPackageTypeDocument = packageType => {
  return packageType?.toLowerCase() === PACKAGE_TYPE_DOCUMENT
}

export const getPackageTypeLabel = packageType => {
  let target = PACKAGE_TYPE.filter(v => v.key === packageType)
  return target.length > 0 ? target[0].label : '-'
}

export const getWeightUnit = (packageType) => isPackageTypeDocument(packageType) ? 'gr' : 'Kg'