import { Form } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ButtonLink, ButtonPrimary } from '../../components/atoms/buttons';
import { FormItemEmail, FormItemPassword } from '../../components/molecules/form-item';
import { VALIDATE_MESSAGES } from '../../config/form-messages';

const { Item } = Form

const FormLogin = ({ submitLoading, login }) => {
  return (
    <Form
      onFinish={login}
      layout="vertical"
      requiredMark={false}
      data-testid="login-form"
      validateMessages={VALIDATE_MESSAGES}
    >
      <FormItemEmail className="mb-15" disabled={submitLoading} initialValue=""/>
      <FormItemPassword className="mb-15" disabled={submitLoading} initialValue=""/>
      <Item>
        <ButtonPrimary 
          className="mt-10 with-shadow btn-font-semi-bold" 
          htmlType="submit" 
          loading={submitLoading}
        >
          Masuk
        </ButtonPrimary>
      </Item>
      <Item className="text-center">
        <ButtonLink>Lupa Password?</ButtonLink>
      </Item>
    </Form>
  );
};

export default FormLogin;

FormLogin.propTypes = {
  submitLoading: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired
}

FormLogin.defaultProps = {
  submitLoading: false,
  login: () => { }
}