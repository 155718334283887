import { SITE_COOKIES } from "../../config"
import { getCookie } from "../../utils/cookies"

const initState = {
  isLogin: getCookie(SITE_COOKIES.TOKEN) !== ""
}

const userReducer = (state = initState, action) => {
  // eslint-disable-next-line
  let { type, payload } = action
  switch (type) {
    default: return {
      ...state
    }
  }
}

export default userReducer