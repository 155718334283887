import React, { useEffect, useState } from 'react';
import MainFooter from '../footer';
import MainHeader from '../header';
import MainSider from '../sider';
import { Layout } from 'antd'

const { Content } = Layout;

const MainLayout = ({ pageTitle, children = 'children' }) => {

  const [siderCollapsed, setSiderCollapsed] = useState(false)

  useEffect(() => {
    const setDocHeight = () => {
      document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
    };
    window.addEventListener('resize', () => {
      setDocHeight();
    });
    window.addEventListener('orientationchange', () => {
      setDocHeight();
    });
    setDocHeight();
    return () => {
      window.removeEventListener('resize', {})
      window.removeEventListener('orientationchange', {})
    }
  })

  const siderProps = {
    siderCollapsed,
    setSiderCollapsed
  }

  return (
    <Layout className="">
      <MainSider {...siderProps} />
      <Layout style={{ marginLeft: siderCollapsed ? 80 : 200, transition: 'margin .2s ease' }}>
        <MainHeader pageTitle={pageTitle} {...siderProps} />
        <Content>
          {children}
        </Content>
        <MainFooter />
      </Layout>
    </Layout>
  );
};

export default MainLayout;