/* eslint-disabled */

import React from 'react';
import { Button, Pagination } from 'antd';
import SearchInput from '../../../components/molecules/search-input';
import TableControlRangePicker from '../../../components/molecules/range-picker';

/* eslint-enabled */

export const TableControl = ({ children, loading, qs, filter, search, daterange, buttons, pagination }) => {

  const { totalData, current, limit, handleOnChange, handleOnShowSizeChange } = pagination
  // const {  } = qs

  return (
    <>
      {/* Header Table Control */}
      <div className="table-control flex flex-justify-space-between mb-20">
        <div className="table-control__search" style={{ width: search?.width || 400 }}>
          <SearchInput { ...search } disabled={ loading } />
        </div>
        { 
          daterange &&
          <div className="table-control__datepicker">
            <TableControlRangePicker disabled={ loading } {...daterange}/>
          </div>
        }
        {
          (filter || buttons) &&
          <div className="table-control__buttons">
            {
              buttons &&
              <div>
                { 
                  buttons.map(({ title, type, className, icon, handleOnClick }, k) => (
                    <Button 
                      type={ type } 
                      className={ className } 
                      onClick={ handleOnClick }
                      key={ k }
                    >
                      { icon } { title }
                    </Button>
                    ) 
                  )
                }
              </div>
            }
            { filter && <Button type="primary-outlined" onClick={ filter.resetFilter } disabled={ loading }>Reset</Button> }
          </div>
        }
      </div>

      {/* Table it self */}
      { children }
      
      {/* Footer Table Control */}
      <div className="table-control flex flex-justify-space-between mt-20">
        <div>Total { totalData } items</div>
        <Pagination
          total={ totalData }
          current={parseInt(current) || 1}
          showSizeChanger={false}
          showQuickJumper
          disabled={loading}
          onChange={ value => handleOnChange(value) }
          onShowSizeChange={ (current, pageSize) => handleOnShowSizeChange(pageSize) }
          defaultPageSize={ limit }
        />
      </div>
    </>
  );
};

export default TableControl;