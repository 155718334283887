/* eslint-disabled */

import React from 'react';
import { Input } from 'antd';

const { Search } = Input

/* eslint-enabled */

const SearchInput = ({ label = true, placeholder, handleSearch, handleOnChange, value, disabled = false }) => {
  return (
    <div className="flex flex-align-center">
      <div className="mr-15"><b>Pencarian</b></div>
      <Search
        allowClear
        placeholder={ placeholder || 'Search' }
        onChange={ e => handleOnChange(e.target.value) }
        onSearch={value => handleSearch(value)}
        style={{ width: '100%' }}
        value={ value }
        disabled={ disabled }
        enterButton
      />
    </div>
  );
};

export default SearchInput;