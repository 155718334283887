import PersonData from '../../components/molecules/person-data';
import { getDate, ID_DETAIL_SHORT_DATETIME_FORMAT, ID_DETAIL_SHORT_DATE_FORMAT } from '../../utils/formatter/date';

// Table Columns
export const DEFAULT_COLUMNS = [
  {
    title: 'Pemesanan',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: createdAt => getDate(createdAt, ID_DETAIL_SHORT_DATETIME_FORMAT)
  },
  {
    title: 'Nomor Booking',
    dataIndex: 'order_id',
    key: 'order_id',
    render: order_id => order_id
  },
  {
    title: 'Nomor Tracking',
    dataIndex: 'awb',
    key: 'awb',
    render: awb => awb
  },
  {
    title: "Data Pengirim",
    dataIndex: "sender",
    key: "sender",
    width: 220,
    render: sender => <PersonData data={sender} />
  },
  {
    title: "Data Penerima",
    dataIndex: "receiver",
    key: "receiver",
    width: 220,
    render: receiver => <PersonData data={receiver} />
  },
  {
    title: "Waktu Pengambilan",
    dataIndex: "detail",
    key: "pickupDate",
    render: detail => (
      <>
        <div>{detail?.pickupDate && getDate(detail?.pickupDate, ID_DETAIL_SHORT_DATE_FORMAT)}</div>
        <b>{detail?.serviceType}</b>
      </>
    )
  },
  {
    title: "Status",
    key: "status",
    // width: 120,
    fixed: 'right',
    render: (text, recors) => recors?.last_tracking?.status ? recors.last_tracking.status?.label : '' 
  },
  {
    title: "Aksi",
    key: "action",
    width: 80,
    fixed: 'right',
  }
];