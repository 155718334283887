import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router, Route, Switch
} from 'react-router-dom'; // eslint-disable-next-line
import { MENU } from '../config';
import Page404 from '../pages/404';
import PageComingSoon from '../pages/coming-soon';
import PageLogin from '../pages/login';
import PageTracking from '../pages/tracking';
import store from '../store';
import PrivateRouter from './private-router';

const AppRouter = () => {
  return (
    <Provider store={ store }>
      <Router>
        <Switch>
          <Route exact path="/" component={PageLogin} />
          <Route exact path={ MENU.LOGIN } component={PageLogin} />
          <PrivateRouter exact path={ MENU.TRACKING } component={PageTracking} />
          <PrivateRouter exact path={ MENU.PROFILE } component={PageComingSoon} />
          <PrivateRouter exact path={ MENU.SETTING } component={PageComingSoon} />
          <Route component={Page404} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default AppRouter;