import { SITE_COOKIES, MENU } from '../config'
import { getCookie, setCookie } from '../utils/cookies'
import { firstCapital } from '../utils/formatter/string'
import Auth from './auth'
import axios from 'axios'
import Shipping from './shipping'


/**
 * 
 * Axios interceptor for unauthenticated
 * 
 */

axios.interceptors.response.use(
  response => response,
  (err) => {
    if (err.response && ((err.response.status === 401 && !err.request.responseURL.includes('v1/auth')))) {
      BACK_TO_LOGIN(true)
    }
    return Promise.reject(err)
  },
)


/**
 * 
 * @param {*} : accect, contentType, token
 * request api configuration
 * 
 */

export const configApi = ({ accept, contentType, token } = {}) => {
  let params = {
    headers: {
      'Accept': accept || 'application/json',
      'Content-Type': contentType || 'application/json',
      'Authorization': `${firstCapital(getCookie(SITE_COOKIES.TOKEN_TYPE)) || 'Bearer'} ${typeof document === 'undefined' ? token : getCookie(SITE_COOKIES.TOKEN)}`,
    },
  }
  return params
}


/**
 * 
 * @param {*} isExpired 
 * Destroy the access
 * 
 */

export const BACK_TO_LOGIN = (isExpired = false) => {
  Object.keys(SITE_COOKIES).forEach(async key => {
    await setCookie(SITE_COOKIES[key], null, -1)
  })
  window.location.replace(MENU.LOGIN)
}



/**
 * 
 * Exports
 * 
 */

const params = {
  url: process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL_DEV,
  config: configApi,
  defaultParams: { limit: 10 }
}

export const AuthApi = Auth(params)
export const ShippingApi = Shipping(params)