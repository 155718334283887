import { Badge, Button, Col, Modal, Row, Skeleton, Form, Input, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { getPackageTypeLabel, getWeightUnit, isPackageTypeDocument } from '../../utils/misc';
import { ShipmentItem } from '../../containers/organisms/shipment-item';
import { ICON_BOX, ICON_DOCUMENT, ICON_DOLLAR, ICON_HASH, STATUS_COMPLETE } from '../../config';
import { numberToMoney, numberToMoneyNoRP } from '../../utils/formatter/currency';
import { firstCapital } from '../../utils/formatter/string';
import { getDate, ID_DETAIL_SHORT_DATETIME_FORMAT } from '../../utils/formatter/date';

const ModalTracking = ({ form, visible, setVisibleModalDetail, fetchLoading, data = {}, trackingStatusDropdown, trackingStatusDropdownLoading, updateShippingStatus }) => {

  const submitRef = useRef()
  const [submitLoading, setSubmitLoading] = useState(false)

  const handleSubmitForm = (values) => {
    updateShippingStatus(values, setSubmitLoading)
  }

  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={() => setVisibleModalDetail(false)}
      footer={false}
      className="modal-tracking text-center"
      closable={false}
      width={720}
      maskClosable={false}
      style={{ top: 30 }}
    >
      <div className="">
        {
          fetchLoading
            ? <Skeleton active />
            : (
              <div className="text-left">
                <div className="modal-header flex flex-justify-space-between mb-30">
                  <div className="pt-10">
                    {/* <Select style={{ width: 150 }} value={data?.detail?.status} disabled={submitLoading}>
                      {SHIPPING_STATUS.map(v => <Select.Option key={v}><Badge status={v.toLowerCase() === STATUS_COMPLETE ? 'default' : 'processing'} text={firstCapital(v)} /></Select.Option>)}
                    </Select> */}
                    <Badge status={data?.detail?.status?.toLowerCase() === STATUS_COMPLETE ? 'default' : 'processing'} text={firstCapital(data?.detail?.status)} />
                  </div>
                  <div>
                    <Button type="default" className="mr-15" disabled={submitLoading} onClick={() => setVisibleModalDetail(false)}>Tutup</Button>
                    <Button type="primary" onClick={() => submitRef.current.click()} loading={submitLoading}>Simpan</Button>
                  </div>
                </div>
                <div className="modal-body">
                  {data && (
                    <TrackingForm
                      form={form}
                      submitRef={submitRef}
                      trackingStatusDropdown={trackingStatusDropdown}
                      trackingStatusDropdownLoading={trackingStatusDropdownLoading}
                      onSubmit={handleSubmitForm}
                      submitLoading={submitLoading}
                      weightUnit={getWeightUnit(data?.detail?.packageType)}
                      packageType={data?.detail?.packageType}
                      {...data}
                    />
                  )}
                </div>
              </div>
            )
        }
      </div>
    </Modal>
  )
}

export default ModalTracking;

// * ---------------------------------------------------------------------------------------------------------------------------------------------------------------

const TrackingForm = ({ form, awb, order_id, packageType, weightUnit, trackingStatusDropdown, trackingStatusDropdownLoading, onSubmit, submitRef, submitLoading, sender, receiver, detail, items, items_actual, createdAt }) => {

  const packageCols = isPackageTypeDocument(packageType)
    ? { ...packageColsDocument }
    : { ...packageColsNonDocument }

  const colsPackage = {
    lg: { span: 5 },
    md: { span: 5 },
    sm: { span: 12 },
    xs: { span: 12 },
  }

  const colsPackage2 = {
    lg: { span: isPackageTypeDocument(packageType) ? 12 : 4 },
    md: { span: isPackageTypeDocument(packageType) ? 12 : 4 },
    sm: { span: 10 },
    xs: { span: 10 },
  }

  const colsPackage3 = {
    lg: { span: isPackageTypeDocument(packageType) ? 12 : 5 },
    md: { span: isPackageTypeDocument(packageType) ? 12 : 5 },
    sm: { span: 10 },
    xs: { span: 10 },
  }

  const isActualAvailable = items_actual && items_actual?.length > 0
  const [itemsContractualSummary, setItemsContractualSummary] = useState()
  const [itemsActualSummary, setItemsActualSummary] = useState()

  useEffect(() => {
    let contractualSummary, actualSummary;
    if (items?.length > 0) {
      let totalWeight = 0
      let totalVolume = 0
      items.forEach(({ quantity, weight, height, length, width }) => {
        quantity = Number(quantity)
        totalWeight += quantity * Number(weight)
        totalVolume += quantity * Number(height) * Number(length) * Number(width)
      })
      contractualSummary = {
        totalWeight,
        totalVolume
      }
      setItemsContractualSummary(contractualSummary)
    }

    if(items_actual?.length > 0) {
      let totalWeight = 0
      let totalVolume = 0
      items_actual.forEach(({ quantity, weight, height, length, width }) => {
        quantity = Number(quantity)
        totalWeight += quantity * Number(weight)
        totalVolume += quantity * Number(height) * Number(length) * Number(width)
      })
      actualSummary = {
        totalWeight,
        totalVolume
      }
      setItemsActualSummary(actualSummary)
    }else{
      setItemsActualSummary(contractualSummary)
    }
  }, [items, items_actual])

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmit}
      size="small"
    >
      <div className="flex flex-justify-space-between mb-20" style={{ fontSize: 12 }}>
        <div>Nomor Pelacakan : <b>{ awb || ''}</b></div>
        <div>Nomor Pesanan : <b>{ order_id || ''}</b></div>
        <div>Waktu Pemesanan : <b>{ (createdAt && getDate(createdAt, ID_DETAIL_SHORT_DATETIME_FORMAT)) || ""}</b></div>
      </div>
      <h5 className="mb-20 heading-bordered">Info Pelacakan</h5>
      <div className="mb-15">
        <Row gutter={30}>
          <Col span={16}>
            <Form.Item
              name="last_tracking_status"
              label="Pilih status pelacakan"
            >
              <Select className="w-full" loading={trackingStatusDropdownLoading} disabled={submitLoading}>
                {
                  Array.isArray(trackingStatusDropdown) && trackingStatusDropdown.length > 0 && trackingStatusDropdown.map((v, k) => (
                    <Select.Option key={k} value={v.value}>
                      {v.label}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="last_tracking_location"
              label="Lokasi saat ini"
            >
              <Input disabled={submitLoading} />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <hr />
      <div className="mt-20">
        <Row gutter={30}>
          <Col span={8}>
            <h5 className="mb-20 heading-bordered">Detail Pengirim</h5>
            <CustomerData {...sender} isSender={true} />
          </Col>
          <Col span={8}>
            <h5 className="mb-20 heading-bordered">Detail Penerima</h5>
            <CustomerData {...receiver} isSender={false} />
          </Col>
          <Col span={8}>
            <h5 className="mb-20 heading-bordered">Detail Pengiriman</h5>
            <ShippingDetails {...detail} />
          </Col>
        </Row>
      </div>
      <br />
      <h5 className="mb-20 heading-bordered mt-15">Detail Barang/Dokumen</h5>
      {/* maxHeight: '30vh' */}
      <div className="shipping-shipment" style={{ height: 'auto', overflow: 'scroll' }}>
        <div className="mb-20">
          <h6 className="mb-10">Berat dan Dimensi Kontraktual</h6>
          <Form.List name="contractual_items">
            {
              (fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(field => (
                    <div style={{ position: 'relative' }} key={field.name}>
                      <ShipmentItem
                        key={field.name}
                        field={field}
                        // loading={submitLoading}
                        onChange={() => { }} // onChangeShipmentItem
                        packageType={packageType}
                        weightUnit={weightUnit}
                        colStyles={packageCols}
                        disabled={true}
                        cols={{
                          colsPackage,
                          colsPackage2,
                          colsPackage3
                        }}
                      />
                    </div>
                  ))}
                </>
              )
            }
          </Form.List>
          {/* Contractual Summary */}
          <WeightAndDimensionSummary
            totalItem={items?.length}
            totalVolume={itemsContractualSummary?.totalVolume}
            totalWeight={itemsContractualSummary?.totalWeight}
            totalPrice={detail?.totalPrice}
            weightUnit={weightUnit}
            packageType={packageType}
          />
        </div>
        <hr />
        <div>
          <h6 className="mt-20 mb-10">Berat dan Dimensi Aktual</h6>
          <Form.List name="actual_items">
            {
              (fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(field => (
                    <div style={{ position: 'relative' }} key={field.name}>
                      <ShipmentItem
                        key={field.name}
                        field={field}
                        onChange={() => { }} // onChangeShipmentItem
                        packageType={packageType}
                        weightUnit={weightUnit}
                        colStyles={packageCols}
                        disabled={submitLoading || isActualAvailable}
                        cols={{
                          colsPackage,
                          colsPackage2,
                          colsPackage3
                        }}
                      />
                    </div>
                  ))}
                </>
              )
            }
          </Form.List>
          {/* Actual Summary */}
          {
            isActualAvailable
              ? (
                <WeightAndDimensionSummary
                  totalItem={items?.length}
                  totalVolume={itemsActualSummary?.totalVolume}
                  totalWeight={itemsActualSummary?.totalWeight}
                  totalPrice={detail?.actualPrice}
                  weightUnit={weightUnit}
                  packageType={packageType}
                />
              )
              : ""
          }
        </div>
      </div>
      <Button style={{ position: 'fixed', top: -9999999, left: -9999999 }} htmlType="submit" ref={submitRef}>SUBMIT</Button>
    </Form>
  )

}

const packageColsDocument = {
  lg: { span: 12 },
  md: { span: 12 },
}

const packageColsNonDocument = {
  lg: { span: 4 },
  md: { span: 4 },
}

const CustomerData = ({ isSender = false, name, addressOne, city, country, postCode, email, phoneNumber }) => {
  return (
    <div>
      <LabelValue
        label={`Nama ${isSender ? "Pengirim" : "Penerima"}`}
        value={name}
        className="mb-5"
      />
      <LabelValue
        label={`Alamat ${isSender ? "Penjemputan" : "Pengiriman"}`}
        value={getAddressFormat(addressOne, city, country, postCode)}
        className="mb-5"
      />
      <LabelValue
        label="Nomor Telepon/HP"
        value={phoneNumber}
        className="mb-5"
      />
      <LabelValue
        label="Email"
        value={email}
      />
    </div>
  )
}

const ShippingDetails = ({ totalPrice, packageType, serviceType, itemName, shipmentPurpose, priceOfGoods }) => {
  return (
    <div>
      <LabelValue
        label="Jenis Pengiriman"
        value={serviceType}
        className="mb-10"
      />
      <LabelValue
        label="Jenis Muatan"
        value={getPackageTypeLabel(packageType)}
        className="mb-10"
      />
      <LabelValue
        label="Nama/Deskripsi Barang"
        value={itemName}
        className="mb-10"
      />
      <LabelValue
        label="Maksud Pengiriman"
        value={shipmentPurpose}
        className="mb-10"
      />
      <LabelValue
        label="Total Harga Barang"
        value={numberToMoney(priceOfGoods)}
      />
    </div>
  )
}

const LabelValue = ({ label, value, className = "" }) => {
  return (
    <div className={className} style={{ fontSize: 12 }}>
      <div><b>{label}</b></div>
      <div>{value}</div>
    </div>
  )
}

const getAddressFormat = (addressOne, city, country, postCode) => {
  return (
    <div>
      <div>{addressOne}</div>
      <div>{city}, {country}</div>
      <div>{postCode}</div>
    </div>
  )
}

const WeightAndDimensionSummary = ({ totalItem, totalVolume, totalWeight, totalPrice, weightUnit, packageType }) => {
  return (
    <div className="flex" style={{ gap: 30 }}>
      <div className="flex">
        <img src={ICON_HASH} alt="..." height={18} width={18} />
        <LabelValue
          label="Jumlah Total"
          value={totalItem}
          className="ml-5"
        />
      </div>
      {
        !isPackageTypeDocument(packageType) && 
        <div className="flex">
          <img src={ICON_BOX} alt="..." height={20} width={20} />
          <LabelValue
            label="Volume Total"
            value={(totalVolume/1000000) + " CBM"}
            className="ml-5"
          />
        </div>
      }
      <div className="flex">
        <img src={ICON_DOCUMENT} alt="..." height={18} width={18} />
        <LabelValue
          label="Berat Total"
          value={numberToMoneyNoRP(totalWeight) + ` ${weightUnit}`}
          className="ml-5"
        />
      </div>
      <div className="flex">
        <img src={ICON_DOLLAR} alt="..." height={18} width={18} />
        <LabelValue
          label="Biaya Pengiriman"
          value={numberToMoney(totalPrice)}
          className="ml-5"
        />
      </div>
    </div>
  )
}