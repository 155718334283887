import moment from 'moment'

export const DEFAULT_LOCALE = 'id'
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD'
export const ID_SHORT_DATE_FORMAT = 'DD MMM YYYY'
export const ID_LONG_DATE_FORMAT = 'DD MMMM YYYY'
export const ID_DETAIL_SHORT_DATE_FORMAT = 'ddd, DD MMM YYYY'
export const ID_DETAIL_SHORT_DATETIME_FORMAT = 'DD MMM YYYY HH:mm'

export const momentLocale = (date = new Date(), locale = DEFAULT_LOCALE) => moment(date).locale(locale)

export const getToday = (format = DEFAULT_DATE_FORMAT) => {
  return momentLocale().format(format)
}

export const getDate = (date, format = DEFAULT_DATE_FORMAT) => momentLocale(date).format(format)

export const todayDateRange = {
  start_date: getToday(),
  end_date: getToday(),
}

export const getDateRange = (start_date, end_date, format = DEFAULT_DATE_FORMAT) => {
  return {
    start_date: getDate(start_date, format),
    end_date: getDate(end_date, format)
  }
}

export const todayDateRangeAlt = {
  created_at_gte: moment().format(),
  created_at_lt: moment().add(1, 'days').format()
}

export const getDateRangeAlt = (start_date, end_date) => {
  return {
    created_at_gte: moment(start_date).format(),
    created_at_lt: moment(end_date).add(1, 'days').format()
  }
}