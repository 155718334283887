import { Row, Col, Table } from "antd"
import { getPackageTypeLabel, getWeightUnit } from "../../utils/misc";
import { numberToMoney } from '../../utils/formatter/currency';

export const expandedRowRender = (data) => {

  const columns = [
    { title: 'Jumlah', dataIndex: 'quantity', key: 'quantity' },
    { title: `Berat (${getWeightUnit(data?.detail?.packageType)})`, dataIndex: 'weight', key: 'weight' },
    { title: 'Panjang (cm)', dataIndex: 'length', key: 'length' },
    { title: 'Lebar (cm)', dataIndex: 'width', key: 'width' },
    { title: 'Tinggi (cm)', dataIndex: 'height', key: 'height' },
  ];

  return (
    <div className="ml-45">
      <Row>
        <Col span={10}>
          <Row className="">
            <Col span={12}>
              <div className="mb-10"><small className="color-dark-grey">Jenis Muatan</small><br />{getPackageTypeLabel(data?.detail?.packageType)}</div>
              <div className="mb-10"><small className="color-dark-grey">Nama / Deskripsi</small><br />{data?.detail?.itemName}</div>
              <div className="mb-10"><small className="color-dark-grey">Maksud Pengiriman</small><br />{data?.detail?.shipmentPurpose}</div>
              <div className="mb-10"><small className="color-dark-grey">Total Harga Barang</small><br /><b>{numberToMoney(data?.detail?.priceOfGoods)}</b></div>
            </Col>
            <Col span={12}>
              <div className="mb-10"><small className="color-dark-grey">Jumlah Total</small><br />N/A</div>
              <div className="mb-10"><small className="color-dark-grey">Berat Total</small><br />{data?.detail?.totalWeight} {getWeightUnit(data?.detail?.packageType)}</div>
              <div className="mb-10"><small className="color-dark-grey">Total Volume</small><br />{data?.detail?.totalVolume / 1000000} m<sup>3</sup></div>
              <div className="mb-10"><small className="color-dark-grey">Total Harga</small><br /><b>{numberToMoney(data?.detail?.actualPrice)}</b></div>
            </Col>
          </Row>
        </Col>
        <Col span={14}>
          <Table
            columns={columns}
            dataSource={data.items}
            pagination={false}
            className="mb-15"
            rowKey={() => Math.random()}
          />
        </Col>
      </Row>
    </div>
  )

};