import { CarOutlined, ExportOutlined } from '@ant-design/icons'
import icon_box from '../assets/icons/icon-box.svg'
import icon_document from '../assets/icons/icon-document.svg'
import icon_dollar from '../assets/icons/icon-dollar.svg'
import icon_hash from '../assets/icons/icon-hash.svg'
import bg_login from '../assets/images/bg-login.jpg'
import brand_logo from '../assets/images/brand-logo.svg'
import img_coming_soon from '../assets/images/coming-soon.png'



// Site Name for SEO Title
export const SITE_NAME = ""

// Site Tagline for SEO Title Tagline
export const SITE_TAGLINE = ""

// Meta data for SEO
// Skip it if this project is CSR
export const METADATA = {
  title: `${SITE_NAME} - ${SITE_TAGLINE}`,
  description: '',
  keywords: ''
}

// Config Cookies, use prefix that represents the app name
// rename the admin keyword
export const SITE_COOKIES = {
  NAME: '__admin_name',
  EMAIL: '__admin_email',
  USERNAME: '__admin_username',
  TOKEN: '__admin_token',
  TOKEN_TYPE: '__admin_token_type',
  ROLEID: '__admin_role_id',
}

// Pointing API URL
export const API_URL = process.env.NODE_ENV === 'production'
  ? process.env.REACT_APP_API_URL
  : process.env.REACT_APP_API_URL_DEV

// Setting AppMenu
export const MENU = {
  LOGIN: '/',
  DASHBOARD: '/dashboard',
  TRACKING: '/tracking',
  PROFILE: '/profile',
  SETTING: '/pengaturan',
  LOGOUT: '/keluar',
}

// Setting Sider AppMenu 
export const NAV_MENU = [
  { icon: <CarOutlined />, label: 'Tracking', href: MENU.TRACKING },
  { icon: <ExportOutlined />, label: 'Keluar', href: MENU.LOGOUT },

]

// Setting Access roles if api not provide it
export const ROLES = {
  super_admin: {
    access: [MENU.DASHBOARD, MENU.ADMIN, MENU.DOC, MENU.SIGNOUT]
  },
}

// Default Brand Logo
export const BRAND_LOGO = brand_logo

// Default Background Image
export const BACKGROUND_LOGIN = bg_login

// Default Coming Soon Image
export const IMAGE_COMING_SOON = img_coming_soon

export const ICON_BOX = icon_box
export const ICON_DOCUMENT = icon_document
export const ICON_HASH = icon_hash
export const ICON_DOLLAR = icon_dollar

export const PACKAGE_TYPE_DOCUMENT = 'document'
export const PACKAGE_TYPE_NON_DOCUMENT = 'non_document'

export const PACKAGE_TYPE = [
  { key: PACKAGE_TYPE_DOCUMENT, label: 'Dokumen' },
  { key: PACKAGE_TYPE_NON_DOCUMENT, label: 'Barang' },
]

export const STATUS_REQUEST = 'request'

export const STATUS_COMPLETE = 'complete'

export const SHIPPING_STATUS = [
  STATUS_REQUEST,
  STATUS_COMPLETE
]