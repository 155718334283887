/* eslint-disable */

import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { AuthApi } from '../../api';
import { showErrorMessage } from '../../utils/error';
import { BRAND_LOGO, SITE_COOKIES, MENU, BACKGROUND_LOGIN } from '../../config';
import { setCookies } from '../../utils/cookies';
import FormLogin from './form';
import { useHistory } from "react-router-dom";

/* eslint-enable */

const PageLogin = () => {

  let history = useHistory();

  const { isLogin } = useSelector(state => state.userReducer)
  const [submitLoading, setSubmitLoading] = useState(false)

  const login = (values) => {
    setSubmitLoading(true)
    AuthApi.login(values)
      .then(({ status, data }) => {
        if (status === 200) setLogin(data, history)
      }).catch(err => {
        showErrorMessage(err)
        setSubmitLoading(false)
      })
  }

  useEffect(() => {
    if (isLogin) history.push(MENU.TRACKING)
  }, [isLogin, history])

  return (
    !isLogin &&
    <div className="login" style={{ backgroundImage: `url(${BACKGROUND_LOGIN})` }}>
      <div className="container-fluid login-container">
        <div className="login-wrapper p-30">
          <img className="login__brand-logo mt-30 mb-30" src={BRAND_LOGO} alt="..." />
          <FormLogin login={login} submitLoading={submitLoading} />
        </div>
      </div>
    </div>
  );
};

export default PageLogin;

export const setLogin = (data, history) => {
  let { access_token, tokenType, user: { username, role } } = data
  let expires_in = 1000
  let cookiesData = [
    { cname: SITE_COOKIES.USERNAME, cvalue: username, exdays: expires_in },
    { cname: SITE_COOKIES.ROLE, cvalue: role, exdays: expires_in },
    { cname: SITE_COOKIES.TOKEN, cvalue: access_token, exdays: expires_in },
    { cname: SITE_COOKIES.TOKEN_TYPE, cvalue: tokenType, exdays: expires_in },
  ];
  setCookies(cookiesData);
  setTimeout(() => {
    history.push(MENU.TRACKING)
  }, 500)
}