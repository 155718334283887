import { CheckOutlined, CopyOutlined, FormOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Tooltip } from "antd";
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ShippingApi } from "../../api";
import { Card } from '../../components/atoms/card';
import { TabButton } from '../../components/atoms/tab-button';
import { MENU } from '../../config';
import MainLayout from '../../containers/organisms/layout/main';
import MainTable from '../../containers/organisms/table';
import { ShippingService } from '../../services';
import { showErrorNotification } from "../../utils/error";
import { getDateRange } from '../../utils/formatter/date';
import { DEFAULT_COLUMNS } from './columns';
import { expandedRowRender } from './expanded-row';
import ModalTracking from './modal';


const DEFAULT_SUMMARY_CARDS = [
  { title: 'Semua', key: "shippingTotal", value: '...', icon: <CopyOutlined /> },
  { title: 'Request', key: "shippingRequest", value: '...', icon: <FormOutlined /> },
  { title: 'Complete', key: "shippingComplete", value: '...', icon: <CheckOutlined /> },
];

const PageTracking = (props) => {

  const location = useLocation();
  const [form] = Form.useForm()

  // Summary Card
  const [summaryCards, setSummaryCards] = useState(DEFAULT_SUMMARY_CARDS);
  // Tabs
  const [tabs] = useState(DEFAULT_SUMMARY_CARDS.map((v, k) => { v.disabled = k !== 0; return v })) // Semua Tab Only
  const [activeTab, setActiveTab] = useState(0);
  // Tables
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState(DEFAULT_COLUMNS)
  const [tableLoading, setTableLoading] = useState(false);
  const [searchInput, setSearchInput] = useState('')
  const [pagination, setPagination] = useState({
    total: 0
  })
  const [dateRange, setDateRange] = useState({});

  // Modal Settings
  const [detailData, setDetailData] = useState()
  const [detailLoading, setDetailLoading] = useState(false)
  const [visibleModalDetail, setVisibleModalDetail] = useState(false)

  const [trackingStatusDropdown, setTrackingStatusDropdown] = useState([])
  const [trackingStatusDropdownLoading, setTrackingStatusDropdownLoading] = useState(false)

  const [query, setQuery] = useState()
  const [page, setPage] = useState(query?.page)
  const [limit, setLimit] = useState(query?.limit)

  // QueryString

  // Getting Query String from current URL
  // let query = queryString.parse(location.search); // eslint-disable-next-line
  // let { page, limit } = query;

  // End of QueryString


  // Props

  // Setting table : dataSource, column, [tab, filter, search, daterange], pagination, loading
  // For every state change it will fetch data using handleFetch(action_type for define the action, value of action, query string location, thisPage URL, and history for back to this page)
  const tableProps = {
    dataSource,
    columns,
    expandedRowRender,
    tab: {
      tabs,
      handleOnChangeTab: (index) => {
        console.log('TAB CHANGE TO', index)
      },
    },
    // filter: {
    // resetFilter: () => handleFetch('RESET', '', location.search, thisPage, history),
    // },
    search: {
      placeholder: 'Cari berdasarkan no booking',
      value: searchInput,
      handleOnChange: value => setSearchInput(value),
      handleSearch: value => {
        setTableLoading(true)
        let paramsQuery = "";
        const paramsObject = {
          page: 1,
          ...dateRange,
          'filters[senders.name][like]': value
        };
        paramsQuery = queryString.stringify(paramsObject);
        ShippingApi.searchShipping(paramsQuery)
          .then(response => {
            let { status } = response
            if (status === 200) {
              let { data, total, currentPage } = response.data
              setDataSource(data);
              setPagination({
                page: currentPage,
                total
              })
            }
            setTableLoading(false)
          }).catch(err => {
            console.log(err);
          })
      },
    },
    pagination: {
      // pagination current and page handled by URL Params, only total data
      totalData: pagination?.total,
      current: page,
      limit: limit,
      handleOnChange: page => {
        props.history.push(`${MENU.TRACKING}?page=${page}`)
      },
      handleOnShowSizeChange: limit => { }
    },
    daterange: {
      handleOnChange: results => {
        let startTime = "";
        let endTime = "";
        results.map((value, key) => key === 0 ? startTime = value._d : endTime = value._d);
        setDateRange(getDateRange(startTime, endTime));
      }
    },
    scroll: { x: 1300 },
    loading: tableLoading,
  };

  // End of Props


  // Methods 

  const getShippingList = (query) => {
    ShippingService.list({
      params: query,
      setLoading: setTableLoading,
      callback: (responseData) => {
        let { data, total, currentPage } = responseData
        setDataSource(data);
        setPagination({
          page: currentPage,
          total
        })
      }
    })
  }

  const getShippingCount = useCallback((cards) => {
    if(!cards) cards = DEFAULT_SUMMARY_CARDS
    ShippingService.count({
      cards,
      callback: (cards) => setSummaryCards(cards)
    })
  }, [])

  const getShippingDetail = (record) => {
    setVisibleModalDetail(true)
    setDetailLoading(true)
    setDetailData(record)
    form.setFieldsValue({
      contractual_items: record?.detail?.shipment,
      actual_items: record?.items_actual?.length > 0 ? record?.items_actual : record?.detail?.shipment,
      last_tracking_status: record?.last_tracking?.status?.value,
      last_tracking_location: record?.last_tracking?.location
    })
    setTimeout(() => {
      setDetailLoading(false)
    }, 500)

  }

  const getTrackingStatusDropdown = () => {
    ShippingService.trackingStatus({
      setLoading: setTrackingStatusDropdownLoading,
      callback: (data) => setTrackingStatusDropdown(data)
    })
  }

  const updateShippingStatus = (values, setLoading) => {
    if (setLoading) setLoading(true)
    const { last_tracking_location, last_tracking_status, actual_items } = values
    let payload = {
      id: detailData?._id,
      tracking_status: {
        value: last_tracking_status,
        location: last_tracking_location
      },
      shipment_actual: actual_items
    }
    ShippingApi.updateStatus(payload)
      .then(response => {
        const { status } = response
        if (status === 200) {
          setDetailData()
          setDetailLoading(false)
          setVisibleModalDetail(false)
          getShippingList(query)
          getShippingCount(summaryCards)
        }
      }).catch(err => showErrorNotification(err))
      .finally(() => setLoading && setLoading(false))
  }

  // End of Methods


  // Effects

  useEffect(() => {
    if(query) getShippingList(query)
  }, [query])

  useEffect(() => {
    let query = queryString.parse(location.search); // eslint-disable-next-line
    let { page, limit } = query;
    setQuery({ ...query })
    setPage(page)
    setLimit(limit)
  }, [location.search])

  useEffect(() => {
    getShippingCount()
  }, [getShippingCount]);

  useEffect(() => {
    let _columns = columns
    _columns[_columns.length - 1]['render'] = (record) => (
      <Row gutter={[5, 5]}>
        <Col span={12} className="flex">
          <Tooltip placement="bottom" title="Detail">
            <Button type="dashed" size="small" className="ml-5" onClick={() => getShippingDetail(record)}><RightOutlined /></Button>
          </Tooltip>
        </Col>
      </Row>
    )
    setColumns(_columns)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (visibleModalDetail && trackingStatusDropdown.length === 0) {
      getTrackingStatusDropdown()
    }
  }, [visibleModalDetail, trackingStatusDropdown])

  // End of Effects

  // * Props

  return (
    <MainLayout pageTitle="Dashboard">
      <div className="container-fluid page-dashboard">
        <div className="page-dashboard__summary-cards">
          <div className="container p-30">
            {summaryCards.map((v) => (
              <Card key={v.title} {...v} />
            ))}
          </div>
        </div>
        <div className="page-dashboard__tab-buttons">
          {summaryCards.map((v, k) => (
            <TabButton
              key={v.title}
              {...v}
              isActive={k === activeTab}
              index={k}
              onClick={setActiveTab}
              disabled={v.disabled}
            />
          ))}
        </div>
        <div className="page-dashboard__table p-30">
          <MainTable {...tableProps} />
        </div>
        <ModalTracking
          form={form}
          visible={visibleModalDetail}
          setVisibleModalDetail={setVisibleModalDetail}
          fetchLoading={detailLoading}
          trackingStatusDropdown={trackingStatusDropdown}
          trackingStatusDropdownLoading={trackingStatusDropdownLoading}
          data={detailData}
          updateShippingStatus={updateShippingStatus}
        />
      </div>
    </MainLayout>
  );
};

export default PageTracking;