import React from 'react';
import { Layout, Menu } from 'antd'
import { BRAND_LOGO, MENU, NAV_MENU } from '../../../../config';
import { Link, useLocation } from 'react-router-dom'
import { BACK_TO_LOGIN } from '../../../../api';

const { Sider } = Layout;

const MainSider = ({ siderCollapsed }) => {

  let location = useLocation();

  return (
    <Sider
      trigger={null}
      className="main-sider"
      collapsible
      collapsed={siderCollapsed}
    >
      <div className="p-30">
        <img className="main-sider__brand-logo" src={BRAND_LOGO} alt="branch-logo" />
      </div>
      <Menu
        defaultSelectedKeys={[location?.pathname]}
        className="main-sider__menu"
        mode="inline"
      >
        {
          NAV_MENU.map(({ label, href, icon }) => (
            <Menu.Item key={href} icon={icon}>
              <Link to={href} onClick={e => { if (href === MENU.LOGOUT) { e.preventDefault(); BACK_TO_LOGIN() } }}>
                {label}
              </Link>
            </Menu.Item>
          ))
        }
      </Menu>
    </Sider>
  );
};

export default MainSider;