import { Button } from 'antd'
import PropTypes from 'prop-types';

export const TabButton = ({ className, title, isActive, index, onClick, disabled }) => {
  return (
    <Button
      type="link"
      className={`tab-button ${className} ${isActive ? 'active' : ''}`}
      onClick={(e) => {
        e.preventDefault();
        onClick(index);
      }}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};

TabButton.propTypes = {
  className: PropTypes.string,
};

TabButton.defaultProps = {
  className: '',
};
