import React from 'react';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

const TableControlRangePicker = ({ disabled,  handleOnChange}) => {
  return (
    <div className="flex flex-align-center ml-30">
      <div className="mr-15"><b>Tanggal Mulai & Akhir</b></div>
      <RangePicker disabled={ disabled } onChange={handleOnChange}/>
    </div>
  );
};

export default TableControlRangePicker;