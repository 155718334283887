import React from 'react';
import { useLocation } from 'react-router-dom';
import { IMAGE_COMING_SOON } from '../../config';
import MainLayout from '../../containers/organisms/layout/main';

const PageComingSoon = () => {

  const location = useLocation()

  return (
    <MainLayout pageTitle={ location?.pathname.replace('/', '') }>
      <div className="page-coming-soon flex flex-centers">
        <div className="text-center">
          <img src={IMAGE_COMING_SOON} alt="..." height={300}/>
          <h3 className="mt-30 color-primary">... coming soon ...</h3>
        </div>
      </div>
    </MainLayout>
  );
};

export default PageComingSoon;