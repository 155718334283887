import PropTypes from 'prop-types';

export const Card = ({ className, title, value, icon }) => {
  return (
    <div className={`card ${className}`}>
      <div className="card__content">
        <div className="card__content__title color-primary">
          { title }
        </div>
        <div className="card__content__value">
          <h4>{ value }</h4>
        </div>
      </div>
      { icon }
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.any,
};

Card.defaultProps = {
  className: '',
  title: '',
  value: '',
};
