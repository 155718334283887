import { message, notification } from "antd"

const DEFAULT_ERROR_MESSAGE = "Terjadi kesalahan, mohon coba beberapa saat lagi"

export const getErrorMessage = err => {
  let errorMessage = DEFAULT_ERROR_MESSAGE
  if(err instanceof Error){
    let { response } = err
    errorMessage = response?.statusText
    if(typeof response == 'object'){
      if('data' in response) {
        let { data: { message } } = response
        if(message) {
          errorMessage = message
        }
      }
    }else{
      let { message } = err
      switch(message){
        case 'Network Error' : errorMessage = 'Mohon periksa koneksi internet anda'; break;
        default : if(typeof message !== undefined) errorMessage = message
      }
    }
  }else if(typeof err === 'string') errorMessage = err
  return errorMessage;
}

export const showErrorMessage = (err, top) => {
  // message.config({
  //   top: top === undefined ? 120 : top
  // })
  message.error(getErrorMessage(err))
}

export const showSuccessMessage = (txt, top) => {
  // message.config({
  //   top: top === undefined ? 120 : top
  // })
  message.success(txt)
}

export const showNotification = (type, message, description) => {
  notification[type]({
    message,
    description,
  });
}

export const showErrorNotification = (err) => {
  showNotification('error', 'Terjadi Kesalahan', getErrorMessage(err))
}

export const showSuccessNotification = (message) => {
  showNotification('success', 'Berhasil', message)
}