/* eslint-disable */

export const encodeSlug = (txt, separator) => {
  return txt && txt.split(separator).join('_').toLowerCase()
}

export const decodeSlug = (txt, separator) => {
  return txt && txt.split('_').join(separator).toLowerCase()
}

export const firstCapital = text => text && text.charAt(0).toUpperCase() + text.substr(1).toLowerCase()

export const wordsFirstCapital = text => {
  let split = text.split(' ')
  if(split.length > 0){
    return split.map(txt => firstCapital(txt)).join(' ')
  }else{
    return firstCapital(text)
  }
}

export const stringOrNumber = qs => {
  return !isNaN(+qs) ? +qs : qs
}