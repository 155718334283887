import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import React from 'react';
import { getFullAddress } from '../../utils/formatter/address';

const { Panel } = Collapse;

const PersonData = ({ data }) => {
  return (
    <div className="person-data">
      <Collapse
        bordered={false}
        className="person-data__detail-collapse"
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        ghost
      >
        <Panel header={<span className="mb-5">{data?.name}</span>} key="1" className="person-data__detail-collapse__panel">
          <div className="mb-15">
            <b>Alamat:</b>
            <div>{getFullAddress(data)}</div>
          </div>
          <div className="mb-15">
            <b>No HP:</b>
            <div>{data?.phoneNumber}</div>
          </div>
          <div className="">
            <b>Email:</b>
            <div>{data?.email}</div>
          </div>
        </Panel>
      </Collapse>
    </div>
  )
};

export default PersonData;